import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { useStep } from "../../context/StepContext";
import { AppDispatch, RootState } from "../../store";
import Chip from "@mui/material/Chip";
import { ServicesList } from "./styles";
import KLButton from "../../components/KLButton";
import { fetchServices } from "../../store/duckers/services/thunk";
import { setCustomerService } from "../../store/duckers/customers/slice";
import {
  ChipServiceDescription,
  chipSx,
  ChipWrapper,
  Container,
  InfoText,
  Subtitle,
  TextIconContainer,
  Title,
} from "../styles";
import KLCustomerBookingProgress from "../../components/KLCustomerBookingProgress";
import KLPleaseWait from "../../components/KLPleaseWait";
import { ServiceWithId } from "../../constants/types";
import { cancellationOptions, getDurationLabel } from "../../utils";
import useLocalStorage from "../../hooks/useLocalStorage";
import { STORAGE_KEYS } from "../../constants/localStorage";
import ListIcon from "@mui/icons-material/List";
const getCancellationLabel = (cancellationPolicy: number | undefined) => {
  if (cancellationPolicy === undefined) return null;
  const option = cancellationOptions.find(
    (opt) => opt.value === cancellationPolicy,
  );
  return option ? `ביטול ${option.label}` : null;
};

const CustomerServicesScreen: React.FC = () => {
  const navigate = useNavigate();
  const { incrementStep } = useStep();
  const dispatch = useDispatch<AppDispatch>();
  const { businessId: urlBusinessId } = useParams<{ businessId: string }>();

  const { storedValue: storedBusinessId, setValue: setBusinessIdStorage } =
    useLocalStorage<string | null>(STORAGE_KEYS.BusinessId, null);

  const [businessId, setBusinessId] = useState<string | null>(
    urlBusinessId || storedBusinessId,
  );

  useEffect(() => {
    if (urlBusinessId && urlBusinessId !== storedBusinessId) {
      setBusinessId(urlBusinessId);
      setBusinessIdStorage(urlBusinessId);
    }
  }, [urlBusinessId]);

  const {
    storedValue: storedSelectedServiceFromStorage,
    setValue: setSelectedServiceStorage,
  } = useLocalStorage<ServiceWithId | null>(STORAGE_KEYS.SelectedService, null);

  const { customerService: storedSelectedServiceFromRedux } = useSelector(
    (state: RootState) => state.customers,
  );

  const [selectedService, setSelectedService] = useState<ServiceWithId | null>(
    storedSelectedServiceFromRedux || storedSelectedServiceFromStorage,
  );

  const { fetchingServices, services } = useSelector(
    (state: RootState) => state.services,
  );

  useEffect(() => {
    if (!businessId) {
      return;
    }
    dispatch(fetchServices(businessId));
  }, [businessId, dispatch]);

  useEffect(() => {
    if (
      storedSelectedServiceFromRedux &&
      !isEqual(selectedService, storedSelectedServiceFromRedux)
    ) {
      setSelectedService(storedSelectedServiceFromRedux);
      setSelectedServiceStorage(storedSelectedServiceFromRedux);
    }
  }, [storedSelectedServiceFromRedux]);

  useEffect(() => {
    if (!selectedService && storedSelectedServiceFromStorage) {
      setSelectedService(storedSelectedServiceFromStorage);
      dispatch(setCustomerService(storedSelectedServiceFromStorage));
    }
  }, [selectedService, storedSelectedServiceFromStorage, dispatch]);

  const handleServiceSelect = (service: ServiceWithId) => {
    setSelectedService((prevSelected) =>
      isEqual(prevSelected, service) ? null : service,
    );
  };

  const handleConfirm = () => {
    if (!selectedService || !businessId) {
      return;
    }
    dispatch(setCustomerService(selectedService));
    incrementStep();
    setSelectedServiceStorage(selectedService);
    navigate(`/${businessId}/slots`);
  };

  if (!businessId) {
    return <Navigate to="/not-authorized" replace />;
  }

  return (
    <Container>
      <KLCustomerBookingProgress currentStep={2} />
      {fetchingServices ? (
        <KLPleaseWait text="בודקים אילו שירותים יש לעסק להציע" />
      ) : (
        <>
          {!services.length ? (
            <>
              <Title>אין דרך קלה להגיד את זה אבל...</Title>
              <Subtitle>משהו לא עובד :(</Subtitle>
              <InfoText>אפשר לנסות שוב במועד מאוחר יותר</InfoText>
            </>
          ) : (
            <>
              <TextIconContainer>
                <ListIcon fontSize="large" />
                <Title>מה בא לכם לקבוע?</Title>
              </TextIconContainer>
              <InfoText>כאן בוחרים את סוג השירות שרוצים לקבל מהעסק</InfoText>
              <ServicesList>
                {services.map((service) => (
                  <ChipWrapper key={service.id}>
                    {" "}
                    <Chip
                      sx={chipSx(isEqual(selectedService, service))}
                      label={
                        <>
                          <ChipServiceDescription>
                            {service.description}
                          </ChipServiceDescription>
                          {!service.showWithoutPrice && (
                            <span>{service.price} ש״ח | </span>
                          )}
                          {service.duration &&
                            getDurationLabel(service.duration)}
                          {service.cancellationPolicy && (
                            <>
                              {" | "}
                              {getCancellationLabel(service.cancellationPolicy)}
                            </>
                          )}
                        </>
                      }
                      color={
                        isEqual(selectedService, service)
                          ? "primary"
                          : "default"
                      }
                      onClick={() => handleServiceSelect(service)}
                      style={{ padding: "20px", width: "100%" }}
                    />
                  </ChipWrapper>
                ))}
              </ServicesList>
              <KLButton
                title={"ממשיכים"}
                onClick={handleConfirm}
                disabled={!selectedService}
              />
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default CustomerServicesScreen;
