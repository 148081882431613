import React, { useCallback, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  ChipWrapper,
  ConfirmationSection,
  Container,
  DaySelection,
  MonthSelection,
  SlotSummary,
  TimeSlots,
} from "./styles";
import Chip from "@mui/material/Chip";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DateTime } from "luxon";
import { bookSlot, fetchSlots } from "../../store/duckers/slots/thunk";
import KLButton from "../../components/KLButton";
import {
  chipSx,
  InfoText,
  Subtitle,
  TextIconContainer,
  Title,
} from "../styles";
import KLCustomerBookingProgress from "../../components/KLCustomerBookingProgress";
import { useStep } from "../../context/StepContext";
import KLPleaseWait from "../../components/KLPleaseWait";
import { ServiceWithId } from "../../constants/types";
import useLocalStorage from "../../hooks/useLocalStorage";
import { STORAGE_KEYS } from "../../constants/localStorage";
import { fetchBusiness } from "../../store/duckers/customers/thunk";
import {
  setCustomerName,
  setCustomerPhone,
  setCustomerService,
} from "../../store/duckers/customers/slice";

const CustomerSlotsScreen: React.FC = () => {
  const navigate = useNavigate();
  const { incrementStep } = useStep();
  const dispatch = useDispatch<AppDispatch>();
  const { businessId: urlBusinessId } = useParams<{ businessId: string }>();

  const { bookingSlot, bookSlotSucceeded, fetchingSlots, availableSlots } =
    useSelector((state: RootState) => state.slots);

  const {
    customerName,
    customerPhone,
    customerService,
    fetchingBusiness,
    business,
  } = useSelector((state: RootState) => state.customers);

  const {
    storedValue: storedBusinessId,
    setValue: setBusinessIdStorage,
    remove: removeBusinessIdStorage,
  } = useLocalStorage<string | null>(STORAGE_KEYS.BusinessId, null);

  const {
    storedValue: storedSelectedService,
    setValue: setSelectedServiceStorage,
    remove: removeSelectedServiceStorage,
  } = useLocalStorage<ServiceWithId | null>(STORAGE_KEYS.SelectedService, null);

  const [businessId, setBusinessId] = useState<string | null>(
    urlBusinessId || storedBusinessId,
  );
  const [selectedService, setSelectedService] = useState<ServiceWithId | null>(
    customerService || storedSelectedService,
  );

  const { storedValue: storedName, setValue: setStoredName } =
    useLocalStorage<string>(STORAGE_KEYS.CustomerName, "");

  const { storedValue: storedPhone, setValue: setStoredPhone } =
    useLocalStorage<string>(STORAGE_KEYS.CustomerPhone, "");

  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [selectedDayLabel, setSelectedDayLabel] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedDisplayTime, setSelectedDisplayTime] = useState<string | null>(
    null,
  );
  const daySectionRef = useRef<HTMLDivElement | null>(null);
  const timeSectionRef = useRef<HTMLDivElement | null>(null);
  const confirmationSectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (customerName !== storedName) {
      setStoredName(customerName);
    }
    if (customerPhone !== storedPhone) {
      setStoredPhone(customerPhone);
    }
  }, [customerName, customerPhone]);

  useEffect(() => {
    if (storedName && customerName !== storedName) {
      dispatch(setCustomerName(storedName));
    }
    if (storedPhone && customerPhone !== storedPhone) {
      dispatch(setCustomerPhone(storedPhone));
    }
  }, [storedName, storedPhone]);

  useEffect(() => {
    if (!urlBusinessId && storedBusinessId) {
      setBusinessId(storedBusinessId);
    }
  }, [urlBusinessId, storedBusinessId]);

  useEffect(() => {
    if (businessId) {
      setBusinessIdStorage(businessId);
    } else {
      removeBusinessIdStorage();
    }
  }, [businessId, setBusinessIdStorage, removeBusinessIdStorage]);

  useEffect(() => {
    if (!customerService && storedSelectedService) {
      setSelectedService(storedSelectedService);
    }
  }, [customerService, storedSelectedService]);

  useEffect(() => {
    if (selectedService) {
      setSelectedServiceStorage(selectedService);
      dispatch(setCustomerService(selectedService));
    } else {
      removeSelectedServiceStorage();
    }
  }, [
    selectedService,
    setSelectedServiceStorage,
    removeSelectedServiceStorage,
  ]);

  const getAvailableSlots = useCallback(() => {
    if (businessId && selectedService && selectedService.duration) {
      dispatch(fetchSlots({ businessId, duration: selectedService.duration }));
    }
  }, [businessId, selectedService]);

  useEffect(() => {
    if (businessId && !business) {
      dispatch(fetchBusiness(businessId));
    }
  }, [businessId, business]);

  useEffect(() => {
    getAvailableSlots();
  }, [getAvailableSlots]);

  useEffect(() => {
    if (bookSlotSucceeded) {
      setSelectedMonth(null);
      setSelectedDay(null);
      setSelectedDayLabel(null);
      setSelectedTime(null);
      setSelectedDisplayTime(null);
      incrementStep();
      navigate(`/${businessId}/success`);
    }
  }, [bookSlotSucceeded, businessId, incrementStep, navigate]);

  const handleConfirm = () => {
    if (
      selectedMonth &&
      selectedDay &&
      selectedTime &&
      selectedService &&
      businessId &&
      customerName &&
      customerPhone &&
      business
    ) {
      const [startTime, endTime] = selectedTime.split(" - ");
      dispatch(
        bookSlot({
          businessId,
          customerName,
          customerPhone,
          serviceId: selectedService.id,
          startTime,
          endTime,
        }),
      );
    }
  };

  const getAvailableMonths = (): string[] => {
    const yearMonthSet = new Set<string>();

    availableSlots.forEach((slot) => {
      const dateTime = DateTime.fromISO(slot.start, { zone: "Asia/Jerusalem" });
      const yearMonth = dateTime.toFormat("yyyy-MM");
      yearMonthSet.add(yearMonth);
    });

    const sortedYearMonths = Array.from(yearMonthSet).sort((a, b) => {
      const dateA = DateTime.fromFormat(a, "yyyy-MM");
      const dateB = DateTime.fromFormat(b, "yyyy-MM");
      return dateA.toMillis() - dateB.toMillis();
    });

    return sortedYearMonths.map((yearMonth) => {
      const [year, month] = yearMonth.split("-");
      const monthNumber = parseInt(month, 10);
      const yearNumber = parseInt(year, 10);
      return DateTime.fromObject({ year: yearNumber, month: monthNumber })
        .setLocale("he")
        .toFormat("LLLL");
    });
  };

  const getAvailableDays = (
    monthName: string,
  ): { date: string; label: string }[] => {
    const filteredSlots = availableSlots.filter((slot) => {
      const dateTime = DateTime.fromISO(slot.start, {
        zone: "Asia/Jerusalem",
      }).setLocale("he");
      const slotMonthName = dateTime.toFormat("LLLL");
      return slotMonthName === monthName;
    });

    const sortedSlots = filteredSlots.sort((a, b) => {
      const aDate = DateTime.fromISO(a.start, { zone: "Asia/Jerusalem" });
      const bDate = DateTime.fromISO(b.start, { zone: "Asia/Jerusalem" });
      return aDate.toMillis() - bDate.toMillis();
    });

    const dayMap = new Map<string, { date: string; label: string }>();

    sortedSlots.forEach((slot) => {
      const dateTime = DateTime.fromISO(slot.start, {
        zone: "Asia/Jerusalem",
      }).setLocale("he");
      const dateKey = dateTime.toISODate();
      const label = dateTime.toFormat("cccc dd/MM");
      if (dateKey && !dayMap.has(dateKey)) {
        dayMap.set(dateKey, { date: dateKey, label });
      }
    });

    return Array.from(dayMap.values());
  };

  const getAvailableTimes = (dateKey: string) => {
    return availableSlots
      .filter((slot) => {
        const dateTime = DateTime.fromISO(slot.start, {
          zone: "Asia/Jerusalem",
        });
        return dateTime.toISODate() === dateKey;
      })
      .map((slot) => {
        const startTime = DateTime.fromISO(slot.start, {
          zone: "Asia/Jerusalem",
        });
        const endTime = DateTime.fromISO(slot.end, {
          zone: "Asia/Jerusalem",
        });
        return {
          startTime: slot.start,
          endTime: slot.end,
          displayTime: `${startTime.toFormat("HH:mm")} - ${endTime.toFormat(
            "HH:mm",
          )}`,
        };
      });
  };

  const months = getAvailableMonths();
  const days = selectedMonth ? getAvailableDays(selectedMonth) : [];
  const times = selectedDay ? getAvailableTimes(selectedDay) : [];

  const handleMonthSelect = (month: string) => {
    setSelectedMonth(month);
    setSelectedDay(null);
    setSelectedDayLabel(null);
    setSelectedTime(null);
  };

  const handleDaySelect = (dayObj: { date: string; label: string }) => {
    setSelectedDay(dayObj.date);
    setSelectedDayLabel(dayObj.label);
    setSelectedTime(null);
  };

  const handleTimeSelect = (
    startTime: string,
    endTime: string,
    displayTime: string,
  ) => {
    setSelectedTime(`${startTime} - ${endTime}`);
    setSelectedDisplayTime(displayTime);
  };

  useEffect(() => {
    if (selectedMonth !== null && daySectionRef.current) {
      daySectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedMonth]);

  useEffect(() => {
    if (selectedDay !== null && timeSectionRef.current) {
      timeSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedDay]);

  useEffect(() => {
    if (selectedTime !== null && confirmationSectionRef.current) {
      confirmationSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedTime]);

  if (!businessId) {
    return <Navigate to="/not-authorized" replace />;
  }

  if (!selectedService) {
    return (
      <Container>
        <KLPleaseWait text="מאתחלים את ההגדרות שלך..." />
      </Container>
    );
  }

  return (
    <Container>
      <KLCustomerBookingProgress currentStep={3} />
      {fetchingSlots || fetchingBusiness ? (
        <KLPleaseWait text={"בודקים זמנים פנויים"} />
      ) : (
        <>
          {!availableSlots.length ? (
            <>
              <Title>אין דרך קלה להגיד את זה אבל...</Title>
              <Subtitle>לא הצלחנו למצוא זמן פנוי :(</Subtitle>
              <InfoText>אפשר לנסות שוב במועד מאוחר יותר</InfoText>
              <KLButton
                title={"אולי בכל זאת?"}
                onClick={getAvailableSlots}
                disabled={fetchingSlots}
              />
            </>
          ) : (
            <>
              <TextIconContainer>
                <CalendarMonthIcon />
                <Title>מתי מתאים לך להגיע?</Title>
              </TextIconContainer>
              <div>
                <Subtitle>באיזה חודש?</Subtitle>
                <MonthSelection>
                  {months.map((month, index) => (
                    <Chip
                      sx={chipSx(selectedMonth === month)}
                      key={index}
                      label={month}
                      color={selectedMonth === month ? "primary" : "default"}
                      onClick={() => handleMonthSelect(month)}
                      style={{ margin: "0.25rem" }}
                    />
                  ))}
                </MonthSelection>
              </div>

              {selectedMonth && (
                <div ref={daySectionRef}>
                  <Subtitle>באיזה יום ב{selectedMonth}?</Subtitle>
                  <DaySelection>
                    {days.map((dayObj) => (
                      <ChipWrapper key={dayObj.date}>
                        <Chip
                          sx={chipSx(selectedDay === dayObj.date)}
                          label={dayObj.label}
                          color={
                            selectedDay === dayObj.date ? "primary" : "default"
                          }
                          onClick={() => handleDaySelect(dayObj)}
                          style={{ width: "100%" }}
                        />
                      </ChipWrapper>
                    ))}
                  </DaySelection>
                </div>
              )}

              {selectedDay && selectedDayLabel && (
                <div ref={timeSectionRef}>
                  <Subtitle>באיזו שעה ב{selectedDayLabel}?</Subtitle>
                  <TimeSlots>
                    {times.map(({ startTime, endTime, displayTime }, index) => (
                      <ChipWrapper key={index}>
                        {" "}
                        <Chip
                          sx={chipSx(
                            selectedTime === `${startTime} - ${endTime}`,
                          )}
                          label={displayTime}
                          color={
                            selectedTime === `${startTime} - ${endTime}`
                              ? "primary"
                              : "default"
                          }
                          onClick={() =>
                            handleTimeSelect(startTime, endTime, displayTime)
                          }
                          style={{ width: "100%" }}
                        />
                      </ChipWrapper>
                    ))}
                  </TimeSlots>
                </div>
              )}

              {selectedTime && business && customerService && (
                <ConfirmationSection ref={confirmationSectionRef}>
                  <SlotSummary>
                    <strong>מצוין!</strong> אז אנחנו קובעים לכם{" "}
                    <strong>{customerService.description}</strong> אצל{" "}
                    <strong>{business.businessName}</strong> ב
                    <strong>{selectedDayLabel}</strong> בשעה{" "}
                    <strong>{selectedDisplayTime}</strong>
                  </SlotSummary>
                  <KLButton
                    title={"הולכים על זה"}
                    onClick={handleConfirm}
                    disabled={!selectedTime || bookingSlot}
                    loading={bookingSlot}
                  />
                </ConfirmationSection>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default CustomerSlotsScreen;
