import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import KLButton from "../../components/KLButton";
import KLTextInput from "../../components/KLTextInput";
import KLSelect from "../../components/KLSelect"; // Import the custom KLSelect component
import {
  ServicesDisclaimer,
  CheckboxWrapper,
  ServicesFieldWrapper,
  ServicesFieldWrapperHorizontal,
  CheckboxLabel,
  ServicesSingleField,
  CollapsibleHeader,
  ServiceTitle,
  CollapsibleContent,
  DeleteButton,
  ServiceSubtitle,
} from "./styles";
import { Service } from "../../constants/types";
import { AppDispatch, RootState } from "../../store";
import { setBusinessServices } from "../../store/duckers/businesses/slice";
import KLBusinessOnboardingProgress from "../../components/KLBusinessOnboardingProgress";
import {
  Container,
  InfoText,
  Title,
  FormWrapper,
  TextIconContainer,
} from "../styles";
import { useStep } from "../../context/StepContext";
import {
  cancellationOptions,
  durationOptions,
  getCancellationPolicyLabel,
  getDurationLabel,
} from "../../utils";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const ServicesSetupScreen: React.FC = () => {
  const navigate = useNavigate();
  const { incrementStep } = useStep();
  const dispatch = useDispatch<AppDispatch>();
  const storedServices = useSelector(
    (state: RootState) => state.businesses.services,
  );

  const [services, setServices] = useState<Service[]>(
    storedServices && storedServices.length > 0
      ? storedServices
      : [
          {
            description: "",
            price: "",
            duration: undefined,
            showWithoutPrice: false,
            cancellationPolicy: undefined,
          },
        ],
  );
  const [isPriceValid, setIsPriceValid] = useState<boolean[]>(
    storedServices && storedServices.length > 0
      ? storedServices.map(
          (service) => service.showWithoutPrice || !!service.price,
        )
      : [false],
  );
  const [expandedServices, setExpandedServices] = useState<boolean[]>(
    services.map((_, index) => index === 0),
  );

  useEffect(() => {
    if (storedServices && storedServices.length > 0) {
      setServices(storedServices);
      setExpandedServices(storedServices.map(() => false)); // Reset expansion state
    }
  }, [storedServices]);

  const handleAddRow = () => {
    setServices([
      ...services,
      {
        description: "",
        price: "",
        duration: undefined,
        showWithoutPrice: false,
        cancellationPolicy: undefined,
      },
    ]);
    setIsPriceValid([...isPriceValid, true]);
    setExpandedServices([...expandedServices, true]);
    setExpandedServices([
      ...expandedServices.map(() => false),
      true,
    ]);
  };

  const handleDeleteRow = (index: number) => {
    const updatedServices = services.filter(
      (_, serviceIndex) => serviceIndex !== index,
    );
    setServices(updatedServices);
    const updatedPriceValid = isPriceValid.filter(
      (_, priceIndex) => priceIndex !== index,
    );
    setIsPriceValid(updatedPriceValid);
    const updatedExpanded = expandedServices.filter(
      (_, expandedIndex) => expandedIndex !== index,
    );
    setExpandedServices(updatedExpanded);
  };

  const handleServiceChange = <K extends keyof Service>(
    index: number,
    field: K,
    value: Service[K],
  ) => {
    const updatedServices = [...services];
    updatedServices[index][field] = value;
    setServices(updatedServices);
  };

  const handlePriceValidation = (index: number, isValid: boolean) => {
    const updatedPriceValid = [...isPriceValid];
    updatedPriceValid[index] = isValid;
    setIsPriceValid(updatedPriceValid);
  };

  const toggleServiceExpansion = (index: number) => {
    setExpandedServices((prev) =>
      prev.map((isExpanded, i) => (i === index ? !isExpanded : false)),
    );
  };

  const isFormValid = useMemo(() => {
    return (
      services.length > 0 &&
      services.every(
        (service, index) =>
          service.description.trim() !== "" &&
          service.duration !== undefined &&
          service.cancellationPolicy !== undefined &&
          (service.showWithoutPrice || isPriceValid[index]),
      )
    );
  }, [services, isPriceValid]);

  const handleSubmit = () => {
    if (isFormValid) {
      const processedServices = services.map((service) => ({
        ...service,
        duration: service.duration as number,
        cancellationPolicy: service.cancellationPolicy as number,
      }));

      dispatch(setBusinessServices(processedServices));
      incrementStep();
      navigate("/onboarding/authorize");
    } else {
      alert("Please fill in all fields correctly");
    }
  };

  const durationOptionsFormatted = useMemo(
    () =>
      durationOptions.map((option) => ({
        label: option.label,
        value: option.value.toString(),
      })),
    [],
  );

  const cancellationOptionsFormatted = useMemo(
    () =>
      cancellationOptions.map((option) => ({
        label: option.label,
        value: option.value.toString(),
      })),
    [],
  );

  return (
    <Container>
      <KLBusinessOnboardingProgress currentStep={2} />
      <TextIconContainer>
        <StorefrontIcon />
        <Title>מה יש לכם להציע?</Title>
      </TextIconContainer>
      <InfoText>כאן מוסיפים את השירותים שאתם מציעים ללקוחות שלכם</InfoText>
      <FormWrapper>
        {services.map((service, index) => (
          <div key={index}>
            <CollapsibleHeader
              $isCollapsed={!expandedServices[index]}
              onClick={() => toggleServiceExpansion(index)}
            >
              <ServiceTitle>
                {service.description.trim() === ""
                  ? "[שם השירות יופיע פה]"
                  : service.description}
                {((service.price && !service.showWithoutPrice) ||
                  service.duration !== undefined ||
                  service.cancellationPolicy !== undefined) && (
                  <ServiceSubtitle>
                    {service.price &&
                      !service.showWithoutPrice &&
                      `${service.price} ש״ח`}
                    {service.price &&
                      !service.showWithoutPrice &&
                      service.duration !== undefined &&
                      " | "}
                    {service.duration !== undefined &&
                      getDurationLabel(service.duration)}
                    {((service.price && !service.showWithoutPrice) ||
                      service.duration !== undefined) &&
                      service.cancellationPolicy !== undefined &&
                      " | "}
                    {service.cancellationPolicy !== undefined &&
                      ` ביטול ${getCancellationPolicyLabel(service.cancellationPolicy)}`}
                  </ServiceSubtitle>
                )}
              </ServiceTitle>
              <DeleteButton onClick={() => handleDeleteRow(index)}>
                מחק
              </DeleteButton>
              {expandedServices[index] ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </CollapsibleHeader>
            {expandedServices[index] && (
              <CollapsibleContent>
                <ServicesFieldWrapper>
                  <KLTextInput
                    type="text"
                    placeholder="מהו השירות אותו תרצו להציע?"
                    value={service.description}
                    onChange={(e) =>
                      handleServiceChange(index, "description", e.target.value)
                    }
                    required
                  />
                </ServicesFieldWrapper>
                <ServicesFieldWrapperHorizontal>
                  <ServicesSingleField>
                    <KLTextInput
                      type="number"
                      placeholder="כמה עולה? (ש״ח)"
                      value={service.price}
                      onChange={(e) =>
                        handleServiceChange(index, "price", e.target.value)
                      }
                      disabled={service.showWithoutPrice}
                      onValidationChange={(isValid) =>
                        handlePriceValidation(index, isValid)
                      }
                      required={!service.showWithoutPrice}
                    />
                  </ServicesSingleField>
                  <ServicesSingleField>
                    <CheckboxWrapper>
                      <CheckboxLabel>
                        <input
                          type="checkbox"
                          checked={service.showWithoutPrice}
                          onChange={(e) =>
                            handleServiceChange(
                              index,
                              "showWithoutPrice",
                              e.target.checked,
                            )
                          }
                        />
                        הצג שירות זה ללא מחיר
                      </CheckboxLabel>
                    </CheckboxWrapper>
                  </ServicesSingleField>
                </ServicesFieldWrapperHorizontal>
                <ServicesFieldWrapperHorizontal>
                  <ServicesSingleField>
                    <KLSelect
                      value={
                        service.duration !== undefined
                          ? service.duration.toString()
                          : ""
                      }
                      onChange={(e) =>
                        handleServiceChange(
                          index,
                          "duration",
                          e.target.value
                            ? parseInt(e.target.value, 10)
                            : undefined,
                        )
                      }
                      placeholder="כמה זמן לוקח?"
                      required
                      options={durationOptionsFormatted}
                    />
                  </ServicesSingleField>
                  <ServicesSingleField>
                    <KLSelect
                      value={
                        service.cancellationPolicy !== undefined
                          ? service.cancellationPolicy.toString()
                          : ""
                      }
                      onChange={(e) =>
                        handleServiceChange(
                          index,
                          "cancellationPolicy",
                          e.target.value
                            ? parseInt(e.target.value, 10)
                            : undefined,
                        )
                      }
                      placeholder="עד מתי ביטול?"
                      required
                      options={cancellationOptionsFormatted}
                    />
                  </ServicesSingleField>
                </ServicesFieldWrapperHorizontal>
              </CollapsibleContent>
            )}
          </div>
        ))}
        <KLButton
          title={"הוסיפו עוד שירותים"}
          onClick={handleAddRow}
          secondary
          noMarginTop
        />
        <ServicesDisclaimer>
          מחירי השירותים הינם בשקלים חדשים וכוללים מע״מ.
        </ServicesDisclaimer>
        <KLButton
          title={"הבא"}
          onClick={handleSubmit}
          disabled={!isFormValid}
          noMarginTop
        />
      </FormWrapper>
    </Container>
  );
};

export default ServicesSetupScreen;
