import React from "react";
import { IconWrapper } from "./styles";
import { EventAvailable } from "@mui/icons-material";

const KLCalendarGearIcon: React.FC = () => {
  return (
    <IconWrapper>
      <EventAvailable />
    </IconWrapper>
  );
};

export default KLCalendarGearIcon;
