import styled from "styled-components";
import colors from "../../constants/colors";

export const SelectWrapper = styled("div")({
  position: "relative",
  width: "100%",
});

export const SelectElement = styled("select")<{ disabled?: boolean }>({
  padding: "12px 12px 12px 40px",
  border: `1px solid ${colors.GREY_3}`,
  borderRadius: "8px",
  fontSize: "16px",
  textAlign: "right",
  width: "100%",
  boxSizing: "border-box",
  appearance: "none",
  backgroundColor: colors.WHITE,
  backgroundImage:
    'url(\'data:image/svg+xml;utf8,<svg fill="%23333" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>\')',
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left 4px center",
  backgroundSize: "24px",
  cursor: "pointer",
  "&:disabled": {
    backgroundColor: colors.GREY_2,
    cursor: "not-allowed",
    "&::placeholder": {
      textDecoration: "line-through",
    },
  },
  "&:focus": {
    outline: `none`,
    borderColor: colors.PURPLE,
  },
});

export const ErrorText = styled("div")({
  fontSize: "13px",
  color: colors.ORANGE,
  marginTop: "0.25rem",
  textAlign: "right",
});
