import React, { createContext, useContext, ReactNode } from "react";
import { STORAGE_KEYS } from "../../constants/localStorage";
import useLocalStorage from "../../hooks/useLocalStorage";

interface StepContextProps {
  currentStep: number;
  incrementStep: () => void;
  setStep: (step: number) => void;
  resetSteps: () => void;
  isRedirecting: boolean;
  onRedirect: (value: boolean) => void;
  maxStepReached: number;
}

const StepContext = createContext<StepContextProps | undefined>(undefined);

export const useStep = (): StepContextProps => {
  const context = useContext(StepContext);
  if (!context) {
    throw new Error("useStep must be used within a StepProvider");
  }
  return context;
};

export const StepProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { storedValue: currentStep, setValue: setCurrentStepStorage } =
    useLocalStorage<number>(STORAGE_KEYS.CurrentStep, 1);

  const { storedValue: maxStepReached, setValue: setMaxStepReachedStorage } =
    useLocalStorage<number>(STORAGE_KEYS.MaxStepReached, 1);

  const { storedValue: isRedirecting, setValue: setIsRedirectingStorage } =
    useLocalStorage<boolean>(STORAGE_KEYS.IsRedirecting, false);

  const incrementStep = () => {
    const newStep = currentStep + 1;
    setCurrentStepStorage(newStep);
    setMaxStepReachedStorage(Math.max(maxStepReached, newStep));
  };

  const setStep = (step: number) => {
    setCurrentStepStorage(step);
    setMaxStepReachedStorage(Math.max(maxStepReached, step));
  };

  const resetSteps = () => {
    setCurrentStepStorage(1);
    setMaxStepReachedStorage(1);
    setIsRedirectingStorage(false);
  };

  const onRedirect = (value: boolean) => {
    setIsRedirectingStorage(value);
  };

  return (
    <StepContext.Provider
      value={{
        currentStep,
        incrementStep,
        setStep,
        resetSteps,
        isRedirecting,
        onRedirect,
        maxStepReached,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};
