import { useEffect, useState } from "react";
import defaultImage from "../assets/images/default.webp";

interface UseBusinessImageProps {
  businessId?: string | null;
  categoryConfig?: { mainImage?: string };
}

const useBusinessImage = ({
  businessId,
  categoryConfig,
}: UseBusinessImageProps) => {
  const [imageSrc, setImageSrc] = useState<string>(defaultImage);

  useEffect(() => {
    const loadImage = async () => {
      if (businessId) {
        try {
          const businessImage = await import(
            `../assets/images/business/${businessId}.jpeg`
          );
          setImageSrc(businessImage.default);
          return;
        } catch (err) {
          console.warn(
            `Business image for ID "${businessId}" not found. Falling back to category image.`,
          );
        }
      }

      if (categoryConfig?.mainImage) {
        try {
          const categoryImage = await import(
            `../assets/images/${categoryConfig.mainImage}.webp`
          );
          setImageSrc(categoryImage.default);
        } catch (error) {
          console.error("Error loading category image:", error);
          setImageSrc(defaultImage);
        }
      } else {
        setImageSrc(defaultImage);
      }
    };

    loadImage();
  }, [businessId, categoryConfig]);

  return imageSrc;
};

export default useBusinessImage;
