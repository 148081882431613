import axios from "axios";
import { AvailabilityType, Slot } from "../constants/types";

class SlotsService {
  private baseApiUrl = process.env.REACT_APP_BACKEND_API;
  private apiKey = process.env.REACT_APP_API_KEY;

  async createSlots(
    businessId: string,
    availabilityType: AvailabilityType,
    slots: any[],
    breakMinutesBetweenSlots: string,
  ): Promise<void> {
    try {
      await axios.post(
        `${this.baseApiUrl}/api/${businessId}/slots`,
        { availabilityType, slots, breakMinutesBetweenSlots },
        {
          headers: {
            "x-api-key": this.apiKey,
          },
        },
      );
    } catch (e) {
      throw new Error("Failed to create slots");
    }
  }

  async fetchSlots(
    businessId: string,
    duration: number,
  ): Promise<{ availableSlots: Slot[]; unavailableSlots: Slot[] }> {
    try {
      const response = await axios.get<{
        availableSlots: Slot[];
        unavailableSlots: Slot[];
      }>(`${this.baseApiUrl}/api/${businessId}/slots`, {
        headers: {
          "x-api-key": this.apiKey,
        },
        params: {
          duration,
        },
      });

      const { availableSlots, unavailableSlots } = response.data;

      if (!availableSlots || !unavailableSlots) {
        throw new Error("Invalid response structure");
      }

      return { availableSlots, unavailableSlots };
    } catch (e) {
      throw new Error("Failed to fetch slots");
    }
  }

  async bookSlot(
    businessId: string,
    customerName: string,
    customerPhone: string,
    serviceId: number,
    startTime: string,
    endTime: string,
  ): Promise<void> {
    try {
      await axios.post(
        `${this.baseApiUrl}/api/${businessId}/slots/book`,
        {
          customerName,
          customerPhone,
          serviceId,
          startTime,
          endTime,
        },
        {
          headers: {
            "x-api-key": this.apiKey,
          },
        },
      );
    } catch (e) {
      throw new Error("Failed to book slot");
    }
  }

  async cancelSlot(businessId: string, slotId: string): Promise<void> {
    try {
      await axios.delete(
        `${this.baseApiUrl}/api/${businessId}/slots/${slotId}`,
        {
          headers: { "x-api-key": this.apiKey },
        },
      );
    } catch (e) {
      throw new Error("Failed to cancel slot");
    }
  }
}

export const slotsService = new SlotsService();
