import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { useNavigate } from "react-router-dom";

import KLButton from "../../../components/KLButton";
import KLTextInput from "../../../components/KLTextInput";
import { fetchCategories } from "../../../store/duckers/categories/thunk";

import {
  setBusinessName,
  setOwnerName,
  setBusinessPhone,
  setBusinessEmail,
  setBusinessCategory,
} from "../../../store/duckers/businesses/slice";
import KLBusinessOnboardingProgress from "../../../components/KLBusinessOnboardingProgress";
import {
  Container,
  FieldWrapper,
  FormWrapper,
  InfoText,
  TextIconContainer,
  Title,
} from "../../styles";
import { useStep } from "../../../context/StepContext";
import KLPleaseWait from "../../../components/KLPleaseWait";
import { EmojiPeople } from "@mui/icons-material";
import KLSelect from "../../../components/KLSelect";

const OwnerInfoScreen: React.FC = () => {
  const navigate = useNavigate();
  const { incrementStep } = useStep();
  const dispatch = useDispatch<AppDispatch>();

  const {
    businessName: storedBusinessName,
    ownerName: storedOwnerName,
    phone: storedPhone,
    email: storedEmail,
    category: storedCategory,
  } = useSelector((state: RootState) => state.businesses);

  const { fetchingCategories, error, categories } = useSelector(
    (state: RootState) => state.categories,
  );

  const [businessName, setBusinessNameState] = useState<string>(
    storedBusinessName || "",
  );
  const [ownerName, setOwnerNameState] = useState<string>(
    storedOwnerName || "",
  );
  const [phone, setPhone] = useState<string>(storedPhone || "");
  const [email, setEmail] = useState<string>(storedEmail || "");

  const [selectedCategory, setSelectedCategory] = useState<string | null>(
    storedCategory || null,
  );

  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isCategoryValid, setIsCategoryValid] = useState<boolean>(true);

  const isFormValid = useMemo(() => {
    return (
      businessName.trim() !== "" &&
      ownerName.trim() !== "" &&
      phone.trim() !== "" &&
      email.trim() !== "" &&
      isPhoneValid &&
      isEmailValid &&
      selectedCategory !== null &&
      isCategoryValid
    );
  }, [
    businessName,
    ownerName,
    phone,
    email,
    isPhoneValid,
    isEmailValid,
    selectedCategory,
    isCategoryValid,
  ]);

  const handleConfirm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFormValid) {
      dispatch(setBusinessName(businessName));
      dispatch(setOwnerName(ownerName));
      dispatch(setBusinessPhone(phone));
      dispatch(setBusinessEmail(email));
      dispatch(setBusinessCategory(selectedCategory));
      incrementStep();
      navigate("/onboarding/services");
    } else {
      alert("Please fill in all fields correctly");
    }
  };

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedCategory(value || null);

    if (value) {
      setIsCategoryValid(true);
    } else {
      setIsCategoryValid(false);
    }
  };

  return (
    <Container>
      <KLBusinessOnboardingProgress currentStep={1} />
      {fetchingCategories ? (
        <KLPleaseWait text="בודקים כמה פרטים במערכת" />
      ) : error ? (
        <>
          <Title>אופס!</Title>
          <InfoText>משהו לא עבד איפשהו... רוצים לנסות שוב?</InfoText>
        </>
      ) : (
        <>
          <TextIconContainer>
            <EmojiPeople />
            <Title>עם מי יש לנו הכבוד?</Title>
          </TextIconContainer>
          <InfoText>נשמח קצת להכיר אתכם ואת העסק שלכם</InfoText>
          <FormWrapper onSubmit={handleConfirm}>
            <FieldWrapper>
              <KLTextInput
                type="text"
                placeholder="שם ומשפחה"
                value={ownerName}
                onChange={(e) => setOwnerNameState(e.target.value)}
                required
              />
            </FieldWrapper>
            <FieldWrapper>
              <KLTextInput
                type="text"
                placeholder="שם העסק"
                value={businessName}
                onChange={(e) => setBusinessNameState(e.target.value)}
                required
              />
            </FieldWrapper>
            <FieldWrapper>
              <KLSelect
                value={selectedCategory || ""}
                onChange={handleCategoryChange}
                placeholder="סוג העסק"
                required
                options={categories.map((category) => ({
                  value: category,
                  label: category,
                }))}
              />
            </FieldWrapper>
            <FieldWrapper>
              <KLTextInput
                type="tel"
                placeholder="טלפון נייד"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                onValidationChange={setIsPhoneValid}
                required
              />
            </FieldWrapper>
            <FieldWrapper>
              <KLTextInput
                type="email"
                placeholder="אימייל"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onValidationChange={setIsEmailValid}
                required
              />
            </FieldWrapper>
            <KLButton title={"ממשיכים"} disabled={!isFormValid} />
          </FormWrapper>
        </>
      )}
    </Container>
  );
};

export default OwnerInfoScreen;
