import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DeleteButton,
  DeleteButtonWrapper,
  DropdownWrapper,
  SlotContent,
  TimeRow,
  CollapsibleWrapper,
  CollapsibleHeader,
  CollapsibleContent,
  NoSlotsText,
  IconWrapper,
  CollapsibleSubtitle,
  CollapsibleTitle,
} from "./styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { createSlots } from "../../store/duckers/slots/thunk";
import KLButton from "../../components/KLButton";
import KLBusinessOnboardingProgress from "../../components/KLBusinessOnboardingProgress";
import { Container, InfoText, Subtitle, Title } from "../styles";
import { useStep } from "../../context/StepContext";
import KLTrashCanIcon from "../../components/icons/KLTrashCanIcon";
import {
  breakOptions,
  dayMapping,
  daysOfWeek,
  generateDateDayOptions,
  generateTimeOptions,
  getBreakLabel,
} from "../../utils";
import { format } from "date-fns";
import { AvailabilityType } from "../../constants/types";
import KLSelect from "../../components/KLSelect";
import {
  BusinessCategory,
  getCategoryConfigTerms,
} from "../../constants/customers/categoryConfigMap";

const NO_AVAILABILITY_TODAY = "אין שעות פנויות ביום זה";

interface SlotsSetupScreenProps {
  isOnboarding?: boolean;
}

const SlotsSetupScreen: React.FC<SlotsSetupScreenProps> = ({
  isOnboarding,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const { incrementStep, setStep } = useStep();
  const { creatingSlots, createSlotsSucceeded } = useSelector(
    (state: RootState) => state.slots,
  );
  const [availabilityType, setAvailabilityType] =
    useState<AvailabilityType | null>(null);
  const [dateDayMap, setDateDayMap] = useState<{ [dayValue: string]: string }>(
    {},
  );
  const [slotsByDay, setSlotsByDay] = useState<{
    [dayValue: string]: Array<{ startTime: string; endTime: string }>;
  }>({});
  const [expandedDay, setExpandedDay] = useState<string>(daysOfWeek[0].label);

  const [breakMinutesBetweenSlots, setBreakMinutesBetweenSlots] = useState<
    string | null
  >(null);
  const [isBreakTimeExpanded, setIsBreakTimeExpanded] = useState<boolean>(true);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const businessId = searchParams.get("businessId");
  const category = searchParams.get("category");

  const { term, pluralTerm } = getCategoryConfigTerms(
    category as BusinessCategory,
  );

  const availableSlots = useMemo(() => {
    const availableSlotsString = searchParams.get("availableSlots");
    return availableSlotsString
      ? JSON.parse(decodeURIComponent(availableSlotsString))
      : null;
  }, [searchParams]);

  const weekdayTimes = useMemo(
    () => generateTimeOptions("07:00", "22:00", 15),
    [],
  );
  const fridayTimes = useMemo(
    () => generateTimeOptions("08:00", "17:00", 15),
    [],
  );

  const handleDayClick = (dayValue: string) => {
    setExpandedDay((prevExpandedDay) =>
      prevExpandedDay === dayValue ? "" : dayValue,
    );
  };

  const isDayExpanded = (dayValue: string) => expandedDay === dayValue;

  const handleAddSlot = (dayValue: string) => {
    setSlotsByDay((prevSlotsByDay) => ({
      ...prevSlotsByDay,
      [dayValue]: [
        ...(prevSlotsByDay[dayValue] || []),
        { startTime: "", endTime: "" },
      ],
    }));
  };

  const handleDeleteSlot = (dayValue: string, slotIndex: number) => {
    setSlotsByDay((prevSlotsByDay) => ({
      ...prevSlotsByDay,
      [dayValue]: prevSlotsByDay[dayValue].filter(
        (_, index) => index !== slotIndex,
      ),
    }));
  };

  const handleSlotChange = (
    dayValue: string,
    slotIndex: number,
    field: "startTime" | "endTime",
    value: string,
  ) => {
    setSlotsByDay((prevSlotsByDay) => {
      const updatedSlots = prevSlotsByDay[dayValue].map((slot, index) => {
        if (index !== slotIndex) return slot;
        return { ...slot, [field]: value };
      });
      return { ...prevSlotsByDay, [dayValue]: updatedSlots };
    });
  };

  const getTimesForDay = (
    dayValue: string,
    selectedStartTime: string,
    isEndTime: boolean,
    slotIndex: number,
  ) => {
    const isFriday = dayValue === "שישי";
    const availableTimes = isFriday ? fridayTimes : weekdayTimes;

    const previousEndTime = slotsByDay[dayValue]
      .slice(0, slotIndex)
      .map((slot) => slot.endTime)
      .reduce((latest, time) => (time > latest ? time : latest), "00:00");

    const startTimes = availableTimes.filter(
      (time) => time.value >= previousEndTime,
    );

    return isEndTime && selectedStartTime
      ? startTimes.filter((time) => time.value > selectedStartTime)
      : startTimes;
  };

  const isFormValid = useMemo(() => {
    return (
      Object.values(slotsByDay).every((slots) =>
        slots.every((slot) => slot.startTime && slot.endTime),
      ) &&
      availabilityType &&
      breakMinutesBetweenSlots
    );
  }, [slotsByDay, availabilityType, breakMinutesBetweenSlots]);

  const handleSubmit = () => {
    if (
      isFormValid &&
      businessId &&
      availabilityType &&
      breakMinutesBetweenSlots
    ) {
      const convertedSlots: Array<{
        day: string;
        date: string;
        startTime: string;
        endTime: string;
      }> = [];

      Object.keys(slotsByDay).forEach((dayValue) => {
        const slots = slotsByDay[dayValue];
        slots.forEach((slot) => {
          convertedSlots.push({
            day: dayValue,
            date: dateDayMap[dayValue] || "",
            startTime: slot.startTime,
            endTime: slot.endTime,
          });
        });
      });

      dispatch(
        createSlots({
          businessId,
          availabilityType,
          slots: convertedSlots,
          breakMinutesBetweenSlots,
        }),
      );
    }
  };

  useEffect(() => {
    if (isOnboarding) {
      setStep(4);
    } else {
      setStep(2);
    }
  }, [isOnboarding, setStep]);

  useEffect(() => {
    if (createSlotsSucceeded) {
      incrementStep();
      if (isOnboarding) {
        navigate(`/onboarding/success?businessId=${businessId}`);
      } else {
        navigate(
          `/availability/success?businessId=${businessId}&availabilityType=${availabilityType}`,
        );
      }
    }
  }, [isOnboarding, createSlotsSucceeded]);

  useEffect(() => {
    if (availableSlots) {
      const { availabilityType, slots: receivedSlots } = availableSlots;
      setAvailabilityType(availabilityType);

      if (
        availabilityType === AvailabilityType.Weekly ||
        availabilityType === AvailabilityType.BiWeekly
      ) {
        const weeksAhead = availabilityType === AvailabilityType.Weekly ? 1 : 2;
        const options = generateDateDayOptions(weeksAhead);
        const dateMap = options.reduce(
          (acc: { [key: string]: string }, option) => {
            acc[option.day] = option.date;
            return acc;
          },
          {} as { [key: string]: string },
        );
        setDateDayMap(dateMap);
      }

      const initialSlotsByDay = daysOfWeek.reduce(
        (
          acc: { [key: string]: Array<{ startTime: string; endTime: string }> },
          day,
        ) => {
          acc[day.label] = [];
          return acc;
        },
        {} as { [key: string]: Array<{ startTime: string; endTime: string }> },
      );

      receivedSlots.forEach(
        (slot: {
          day: string;
          date?: string;
          startTime: string;
          endTime: string;
        }) => {
          const dayLabel = dayMapping[slot.day];
          if (!dayLabel) {
            console.error(`Unknown day: ${slot.day}`);
            return;
          }
          if (!initialSlotsByDay[dayLabel]) {
            initialSlotsByDay[dayLabel] = [];
          }
          initialSlotsByDay[dayLabel].push({
            startTime: slot.startTime,
            endTime: slot.endTime,
          });
        },
      );
      setSlotsByDay(initialSlotsByDay);
    }
  }, [availableSlots]);

  return (
    <Container>
      {isOnboarding && <KLBusinessOnboardingProgress currentStep={4} />}
      <Title>מתי תרצו לקבל לקוחות?</Title>
      {availabilityType === AvailabilityType.Weekly && (
        <Subtitle>
          אלו הימים והשעות שזיהינו שבהם אתם פנויים בשבוע הקרוב
        </Subtitle>
      )}
      {availabilityType === AvailabilityType.BiWeekly && (
        <Subtitle>
          אלו הימים והשעות שזיהינו שבהם אתם פנויים בשבועיים הקרובים
        </Subtitle>
      )}
      {availabilityType === AvailabilityType.WeeklyRecurring && (
        <Subtitle>אלו הימים והשעות שזיהינו שבהם אתם פנויים כל שבוע</Subtitle>
      )}
      <InfoText>אפשר להוסיף, למחוק ולשנות!</InfoText>
      {daysOfWeek.map((day) => (
        <CollapsibleWrapper key={day.value}>
          <CollapsibleHeader
            onClick={() => handleDayClick(day.label)}
            $isCollapsed={!isDayExpanded(day.label)}
          >
            <CollapsibleTitle>
              {availabilityType !== AvailabilityType.WeeklyRecurring &&
              dateDayMap[day.label]
                ? `${day.label} - ${format(new Date(dateDayMap[day.label]), "dd/MM/yy")}`
                : day.label}
              <IconWrapper>
                {isDayExpanded(day.label) ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconWrapper>
            </CollapsibleTitle>
            <CollapsibleSubtitle>
              {slotsByDay[day.label] && slotsByDay[day.label].length > 0
                ? slotsByDay[day.label]
                    .filter((slot) => slot.startTime && slot.endTime)
                    .map((slot, index) => (
                      <div
                        key={index}
                      >{`${slot.startTime} - ${slot.endTime}`}</div>
                    ))
                : NO_AVAILABILITY_TODAY}
            </CollapsibleSubtitle>
          </CollapsibleHeader>
          {isDayExpanded(day.label) && (
            <CollapsibleContent>
              {slotsByDay[day.label] && slotsByDay[day.label].length > 0 ? (
                slotsByDay[day.label].map((slot, index) => (
                  <SlotContent key={index}>
                    <TimeRow>
                      <DropdownWrapper>
                        <KLSelect
                          value={slot.startTime}
                          onChange={(e) =>
                            handleSlotChange(
                              day.label,
                              index,
                              "startTime",
                              e.target.value,
                            )
                          }
                          placeholder="התחלה"
                          required
                          options={getTimesForDay(day.label, "", false, index)}
                          disabled={false}
                        />
                      </DropdownWrapper>
                      <DropdownWrapper>
                        <KLSelect
                          value={slot.endTime}
                          onChange={(e) =>
                            handleSlotChange(
                              day.label,
                              index,
                              "endTime",
                              e.target.value,
                            )
                          }
                          placeholder="סיום"
                          required
                          options={getTimesForDay(
                            day.label,
                            slot.startTime,
                            true,
                            index,
                          )}
                          disabled={false}
                        />
                      </DropdownWrapper>
                      {/* Move the DeleteButtonWrapper inside TimeRow */}
                      <DeleteButtonWrapper>
                        <DeleteButton
                          onClick={() => handleDeleteSlot(day.label, index)}
                        >
                          <KLTrashCanIcon />
                        </DeleteButton>
                      </DeleteButtonWrapper>
                    </TimeRow>
                  </SlotContent>
                ))
              ) : (
                <NoSlotsText>{NO_AVAILABILITY_TODAY}</NoSlotsText>
              )}
              <KLButton
                title={"הוסיפו עוד שעות"}
                onClick={() => handleAddSlot(day.label)}
                secondary
              />
            </CollapsibleContent>
          )}
        </CollapsibleWrapper>
      ))}

      <Title>כמה זמן הפסקה בין {pluralTerm}?</Title>
      <Subtitle>
        כל אחד צריך גם קצת להירגע. כמה זמן לפנות בין {term} ל{term}?
      </Subtitle>
      <CollapsibleWrapper>
        <CollapsibleHeader
          onClick={() => setIsBreakTimeExpanded((prev) => !prev)}
          $isCollapsed={!isBreakTimeExpanded}
        >
          <CollapsibleTitle>
            הפסקה בין {pluralTerm}
            <IconWrapper>
              {isBreakTimeExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconWrapper>
          </CollapsibleTitle>
          <CollapsibleSubtitle>
            {breakMinutesBetweenSlots &&
              getBreakLabel(breakMinutesBetweenSlots)}
          </CollapsibleSubtitle>
        </CollapsibleHeader>
        {isBreakTimeExpanded && (
          <CollapsibleContent>
            <SlotContent>
              <TimeRow>
                <DropdownWrapper>
                  <KLSelect
                    value={breakMinutesBetweenSlots || ""}
                    onChange={(e) =>
                      setBreakMinutesBetweenSlots(e.target.value)
                    }
                    placeholder={`הפסקה בין ${pluralTerm}`}
                    required
                    options={breakOptions}
                    disabled={false}
                  />
                </DropdownWrapper>
              </TimeRow>
            </SlotContent>
          </CollapsibleContent>
        )}
      </CollapsibleWrapper>
      <KLButton
        title={"כבר מסיימים"}
        onClick={handleSubmit}
        disabled={!isFormValid || creatingSlots}
      />
    </Container>
  );
};

export default SlotsSetupScreen;
