import styled from "styled-components";
import colors from "../../constants/colors";
import { FieldWrapper } from "../styles";

export const ServicesFieldWrapper = styled(FieldWrapper)({
  marginBottom: "0.5rem",
});

export const ServicesFieldWrapperHorizontal = styled(ServicesFieldWrapper)({
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
});

export const ServicesSingleField = styled("div")({
  flex: 1,
});

export const CheckboxWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  label: {
    fontSize: "14px",
    color: colors.BLACK,
  },
});

export const CheckboxLabel = styled("label")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  label: {
    fontSize: "14px",
    color: colors.BLACK,
  },
});

export const ServicesDisclaimer = styled("div")({
  width: "100%",
  textAlign: "right",
  fontSize: "12px",
  marginTop: "2rem",
});

export const CollapsibleHeader = styled("div")<{
  $isCollapsed?: boolean;
}>(({ $isCollapsed }) => ({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: colors.PURPLE_LIGHTEST,
  border: `1px solid ${colors.GREY_6}`,
  borderRadius: $isCollapsed ? "8px" : "8px 8px 0 0",
  padding: "0.75rem 1rem",
  cursor: "pointer",
}));

export const CollapsibleContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",
  padding: "1rem 1rem 0.5rem 1rem",
  backgroundColor: colors.WHITE,
  border: `1px solid ${colors.GREY_6}`,
  borderRadius: "0 0 8px 8px",
});

export const ServiceTitle = styled("span")({
  fontSize: "18px",
  fontWeight: "bold",
  color: colors.BLACK,
  flexGrow: 1,
  textAlign: "right",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.25rem",
});

export const ServiceSubtitle = styled("span")({
  fontSize: "12px",
  color: colors.GREY_7,
});

export const DeleteButton = styled("button")({
  background: colors.RED,
  border: "none",
  color: colors.WHITE,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  padding: "0.25rem 0.5rem",
  fontSize: "14px",
  fontWeight: "700",
  marginLeft: "0.25rem",
  height: "100%",
});
