import styled from "styled-components";
import colors from "../../constants/colors";

export const LinkWrapper = styled("div")({
  backgroundColor: colors.GREY_1,
  padding: "0.5rem",
  borderRadius: "8px",
  marginBottom: "1rem",
  fontFamily: "monospace",
  wordBreak: "break-all",
  color: colors.BLACK,
});
