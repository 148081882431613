import styled from "styled-components";
import colors from "../../constants/colors";

export const Container = styled("div")({
  backgroundColor: colors.BACKGROUND,
  borderRadius: "15px",
  padding: "1.25rem",
  color: colors.BLACK,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  textAlign: "center",
  direction: "rtl",
});
