import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MainTitle } from "./styles";
import KLButton from "../../components/KLButton";
import KLCalendarGearIcon from "../../components/icons/KLCalendarConfirmedIcon";
import KLBusinessIcon from "../../components/icons/KLBusinessIcon";
import {
  ButtonWrapper,
  Container,
  Content,
  ContentWrapper,
  Subtitle,
} from "../styles";
import KLReminderIcon from "../../components/icons/KLReminderIcon";
import { useStep } from "../../context/StepContext";

const MainScreen: React.FC = () => {
  const navigate = useNavigate();
  const { resetSteps } = useStep();

  const handleStartOnboarding = () => {
    navigate("/onboarding/owner-info");
  };

  useEffect(() => {
    resetSteps();
  }, [resetSteps]);

  return (
    <Container>
      <MainTitle>קבענו לי!</MainTitle>
      <Subtitle>
        תנו לנו לדאוג לפגישות,
        <br /> ואתם תדאגו לעסק!
      </Subtitle>
      <ContentWrapper>
        <KLCalendarGearIcon />
        <Content>
          אנו מנהלים את לוח הזמנים של העסק שלכם באופן אוטומטי בעזרת חיבור ליומן
          שלכם.
        </Content>
      </ContentWrapper>
      <ContentWrapper>
        <KLReminderIcon />
        <Content>שליחת תזכורת לפני? גם זה עלינו!</Content>
      </ContentWrapper>
      <ContentWrapper>
        <KLBusinessIcon />
        <Content>
          כך אתם יכולים להתמקד במה שחשוב באמת - <br />
          העסק שלכם!
        </Content>
      </ContentWrapper>
      <ButtonWrapper>
        <KLButton title="הרשמה" onClick={handleStartOnboarding} />
      </ButtonWrapper>
    </Container>
  );
};

export default MainScreen;
