import React from "react";
import { useLocation } from "react-router-dom";
import { Divider, FooterContainer, FooterLink } from "./styles";

const Footer: React.FC = () => {
  const location = useLocation();
  const showHomeFooterLink =
    !location.pathname.includes("onboarding") && location.pathname !== "/";

  return (
    <FooterContainer>
      <FooterLink to="/privacy-policy">מדיניות פרטיות</FooterLink>
      <Divider>|</Divider>
      <FooterLink to="/terms-of-service">תנאי שירות</FooterLink>
      {showHomeFooterLink && (
        <>
          <Divider>|</Divider>
          <FooterLink to="/">רוצה קבענו לי גם לעסק שלך?</FooterLink>
        </>
      )}
    </FooterContainer>
  );
};

export default Footer;
