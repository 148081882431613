import React from "react";
import { SelectWrapper, SelectElement, ErrorText } from "./styles";

interface KLSelectProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
  required?: boolean;
  onValidationChange?: (isValid: boolean) => void;
  disabled?: boolean;
  options: { label: string; value: string }[];
  error?: string | null;
}

const KLSelect: React.FC<KLSelectProps> = ({
  value,
  onChange,
  placeholder = "בחר...",
  required,
  onValidationChange,
  disabled,
  options,
  error = null,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    onChange(e);

    if (required && selectedValue === "") {
      onValidationChange?.(false);
      return;
    }
    onValidationChange?.(true);
  };

  return (
    <>
      <SelectWrapper>
        <SelectElement
          value={value}
          onChange={handleChange}
          required={required}
          disabled={disabled}
        >
          <option value="" disabled hidden>
            {placeholder}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </SelectElement>
      </SelectWrapper>
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
};

export default KLSelect;
