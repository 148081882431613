export type BusinessCategory =
  | "ארגון וסידור ארונות"
  | "רופאי משפחה"
  | "רופאי שיניים"
  | "פיזיותרפיסטים"
  | "כירופרקטורים"
  | "פסיכולוגים ומטפלים"
  | "אופטומטריסטים"
  | "מספרות"
  | "מכוני ציפורניים"
  | "מטפלים בעיסוי"
  | "מכוני יופי"
  | "מאפרות"
  | "מכוני ספא"
  | "מכוני הסרת שיער בלייזר"
  | "מאמנים אישיים"
  | "מדריכי יוגה"
  | "מכוני פילאטיס"
  | "סטודיו לריקוד"
  | "בתי ספר לאומנויות לחימה"
  | "חדרי כושר קרוספיט"
  | "סטודיו לספינינג"
  | "עורכי דין"
  | "יועצים משפטיים"
  | "נוטריונים"
  | "מגשרים"
  | "יועצי מס"
  | "רואי חשבון"
  | "מורים פרטיים"
  | "מדריכי מוזיקה"
  | "מורי אומנות"
  | "מאמנים אקדמיים"
  | "בתי ספר לשפות"
  | "תספורות וטיפוח גברים"
  | "קיצוץ ועיצוב זקן"
  | "שירות טיולי כלבים"
  | "שירותי רחיצה לחיות מחמד"
  | "שירותי ניקיון"
  | "שירותי אינסטלציה"
  | "חשמלאים"
  | "הכנה לראיונות"
  | "שירותי תיקונים כלליים"
  | "שירותי גינון"
  | "הדברה"
  | "מעצבי פנים"
  | "צילום אירועים"
  | "צילום מסחרי"
  | "מאמנים לחיים"
  | "מאמני בריאות ותזונה"
  | "מאמנים לקריירה"
  | "מדריכי מיינדפולנס ומדיטציה";

export enum BusinessCategoryGender {
  Male = "male",
  Female = "female",
}

export interface CategoryConfig {
  term: string;
  pluralTerm: string;
  gender: BusinessCategoryGender;
  addressRequired?: boolean;
  mainImage: string;
}

export const categoryConfigMap: { [key in BusinessCategory]: CategoryConfig } =
  {
    "ארגון וסידור ארונות": {
      term: "ביקור",
      pluralTerm: "ביקורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "closet_organizers",
    },
    "רופאי משפחה": {
      term: "תור",
      pluralTerm: "תורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "family_doctors",
    },
    "רופאי שיניים": {
      term: "תור",
      pluralTerm: "תורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "dentists",
    },
    פיזיותרפיסטים: {
      term: "טיפול",
      pluralTerm: "טיפולים",
      gender: BusinessCategoryGender.Male,
      mainImage: "physiotherapists",
    },
    כירופרקטורים: {
      term: "טיפול",
      pluralTerm: "טיפולים",
      gender: BusinessCategoryGender.Male,
      mainImage: "chiropractors",
    },
    "פסיכולוגים ומטפלים": {
      term: "פגישה",
      pluralTerm: "פגישות",
      gender: BusinessCategoryGender.Female,
      mainImage: "psychologists_and_therapists",
    },
    אופטומטריסטים: {
      term: "תור",
      pluralTerm: "תורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "optometrists",
    },
    מספרות: {
      term: "תור",
      pluralTerm: "תורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "barbershops",
    },
    "מכוני ציפורניים": {
      term: "תור",
      pluralTerm: "תורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "nail_salons",
    },
    "מטפלים בעיסוי": {
      term: "טיפול",
      pluralTerm: "טיפולים",
      gender: BusinessCategoryGender.Male,
      mainImage: "massage_therapists",
    },
    "מכוני יופי": {
      term: "טיפול",
      pluralTerm: "טיפולים",
      gender: BusinessCategoryGender.Male,
      mainImage: "beauty_salons_skin_care",
    },
    מאפרות: {
      term: "תור",
      pluralTerm: "תורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "make_up_services",
    },
    "מכוני ספא": {
      term: "טיפול",
      pluralTerm: "טיפולים",
      gender: BusinessCategoryGender.Male,
      mainImage: "clinic",
    },
    "מכוני הסרת שיער בלייזר": {
      term: "טיפול",
      pluralTerm: "טיפולים",
      gender: BusinessCategoryGender.Male,
      mainImage: "laser_hair_removal",
    },
    "מאמנים אישיים": {
      term: "אימון",
      pluralTerm: "אימונים",
      gender: BusinessCategoryGender.Male,
      mainImage: "personal_trainers",
    },
    "מדריכי יוגה": {
      term: "שיעור",
      pluralTerm: "שיעורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "yoga_instructors",
    },
    "מכוני פילאטיס": {
      term: "שיעור",
      pluralTerm: "שיעורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "pilates_instructors",
    },
    "סטודיו לריקוד": {
      term: "שיעור",
      pluralTerm: "שיעורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "dance_studios",
    },
    "בתי ספר לאומנויות לחימה": {
      term: "אימון",
      pluralTerm: "אימונים",
      gender: BusinessCategoryGender.Male,
      mainImage: "martial_arts_schools",
    },
    "חדרי כושר קרוספיט": {
      term: "אימון",
      pluralTerm: "אימונים",
      gender: BusinessCategoryGender.Male,
      mainImage: "crossfit_gyms",
    },
    "סטודיו לספינינג": {
      term: "שיעור",
      pluralTerm: "שיעורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "spinning_studios",
    },
    "עורכי דין": {
      term: "פגישה",
      pluralTerm: "פגישות",
      gender: BusinessCategoryGender.Female,
      mainImage: "lawyer_firms",
    },
    "יועצים משפטיים": {
      term: "פגישה",
      pluralTerm: "פגישות",
      gender: BusinessCategoryGender.Female,
      mainImage: "mediators",
    },
    נוטריונים: {
      term: "פגישה",
      pluralTerm: "פגישות",
      gender: BusinessCategoryGender.Female,
      mainImage: "notary_services",
    },
    מגשרים: {
      term: "פגישה",
      pluralTerm: "פגישות",
      gender: BusinessCategoryGender.Female,
      mainImage: "mediators",
    },
    "יועצי מס": {
      term: "פגישה",
      pluralTerm: "פגישות",
      gender: BusinessCategoryGender.Female,
      mainImage: "tax_consultants",
    },
    "רואי חשבון": {
      term: "פגישה",
      pluralTerm: "פגישות",
      gender: BusinessCategoryGender.Female,
      mainImage: "accountants",
    },
    "מורים פרטיים": {
      term: "שיעור",
      pluralTerm: "שיעורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "private_tutors",
    },
    "מדריכי מוזיקה": {
      term: "שיעור",
      pluralTerm: "שיעורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "music_instructors",
    },
    "מורי אומנות": {
      term: "שיעור",
      pluralTerm: "שיעורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "art_teachers",
    },
    "מאמנים אקדמיים": {
      term: "שיעור",
      pluralTerm: "שיעורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "academic_instructors",
    },
    "בתי ספר לשפות": {
      term: "שיעור",
      pluralTerm: "שיעורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "language_schools",
    },
    "תספורות וטיפוח גברים": {
      term: "תור",
      pluralTerm: "תורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "barber_shops_for_men",
    },
    "קיצוץ ועיצוב זקן": {
      term: "תור",
      pluralTerm: "תורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "mens_beard_trimming",
    },
    "שירות טיולי כלבים": {
      term: "ביקור",
      pluralTerm: "ביקורים",
      gender: BusinessCategoryGender.Male,
      addressRequired: true,
      mainImage: "dog_walkers",
    },
    "שירותי רחיצה לחיות מחמד": {
      term: "ביקור",
      pluralTerm: "ביקורים",
      gender: BusinessCategoryGender.Male,
      addressRequired: true,
      mainImage: "dog_washing_services",
    },
    "שירותי ניקיון": {
      term: "ביקור",
      pluralTerm: "ביקורים",
      gender: BusinessCategoryGender.Male,
      addressRequired: true,
      mainImage: "cleaning_services",
    },
    "שירותי אינסטלציה": {
      term: "ביקור",
      pluralTerm: "ביקורים",
      gender: BusinessCategoryGender.Male,
      addressRequired: true,
      mainImage: "plumbing_services",
    },
    חשמלאים: {
      term: "ביקור",
      pluralTerm: "ביקורים",
      gender: BusinessCategoryGender.Male,
      addressRequired: true,
      mainImage: "electricians",
    },
    "הכנה לראיונות": {
      term: "פגישה",
      pluralTerm: "פגישות",
      gender: BusinessCategoryGender.Female,
      mainImage: "interview_preparation_services",
    },
    "שירותי תיקונים כלליים": {
      term: "ביקור",
      pluralTerm: "ביקורים",
      gender: BusinessCategoryGender.Male,
      addressRequired: true,
      mainImage: "general_repair_services",
    },
    "שירותי גינון": {
      term: "ביקור",
      pluralTerm: "ביקורים",
      gender: BusinessCategoryGender.Male,
      addressRequired: true,
      mainImage: "gardening_services",
    },
    הדברה: {
      term: "ביקור",
      pluralTerm: "ביקורים",
      gender: BusinessCategoryGender.Male,
      addressRequired: true,
      mainImage: "pest_control",
    },
    "מעצבי פנים": {
      term: "פגישה",
      pluralTerm: "פגישות",
      gender: BusinessCategoryGender.Female,
      mainImage: "interior_design_services",
    },
    "צילום אירועים": {
      term: "פגישה",
      pluralTerm: "פגישות",
      gender: BusinessCategoryGender.Female,
      mainImage: "photography_services",
    },
    "צילום מסחרי": {
      term: "פגישה",
      pluralTerm: "פגישות",
      gender: BusinessCategoryGender.Female,
      mainImage: "commercial_photography_services",
    },
    "מאמנים לחיים": {
      term: "פגישה",
      pluralTerm: "פגישות",
      gender: BusinessCategoryGender.Female,
      mainImage: "coaching",
    },
    "מאמני בריאות ותזונה": {
      term: "פגישה",
      pluralTerm: "פגישות",
      gender: BusinessCategoryGender.Female,
      mainImage: "nutrition_consultants",
    },
    "מאמנים לקריירה": {
      term: "פגישה",
      pluralTerm: "פגישות",
      gender: BusinessCategoryGender.Female,
      mainImage: "career_consultants",
    },
    "מדריכי מיינדפולנס ומדיטציה": {
      term: "שיעור",
      pluralTerm: "שיעורים",
      gender: BusinessCategoryGender.Male,
      mainImage: "meditation_mindfulness_trainers",
    },
  };

export const getCategoryConfigTerms = (
  category: BusinessCategory | undefined,
) => {
  const categoryConfig = category ? categoryConfigMap[category] : undefined;
  return {
    term: categoryConfig?.term || "תור",
    pluralTerm: categoryConfig?.pluralTerm || `${categoryConfig?.term}ים`,
    gender: categoryConfig?.gender || BusinessCategoryGender.Male,
  };
};
