import styled from "styled-components";
import colors from "../../constants/colors";

export const SlotWrapper = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  marginBottom: "1.5rem",
  width: "100%",
});

export const DeleteButtonWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});

export const DeleteButton = styled("button")({
  background: "none",
  border: "none",
  color: colors.RED,
  cursor: "pointer",
  fontSize: "1.5rem",
  marginRight: "1rem",
  padding: "0",
  display: "flex",
  alignItems: "center",
  height: "40px",
});

export const SlotContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
});

export const TimeRow = styled("div")({
  display: "flex",
  marginTop: "0.75rem",
  gap: "0.75rem",
});

export const DropdownWrapper = styled("div")({
  flex: "1",
  marginRight: "0.75rem",
  "&:last-child": {
    marginRight: "0",
  },
});

export const CollapsibleWrapper = styled("div")({
  marginBottom: "1.5rem",
  width: "100%",
});

export const CollapsibleHeader = styled("div")<{
  $isCollapsed?: boolean;
}>(({ $isCollapsed }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  padding: "1rem",
  backgroundColor: colors.PURPLE_LIGHTEST,
  border: `1px solid ${colors.GREY_6}`,
  borderRadius: $isCollapsed ? "8px" : "8px 8px 0 0",
}));

export const CollapsibleTitle = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  fontSize: "20px",
  fontWeight: "bold",
  color: colors.BLACK,
});

export const CollapsibleSubtitle = styled("div")({
  display: "flex",
  flexDirection: "column",
  fontSize: "14px",
  fontWeight: "bold",
  color: colors.GREY_7,
  width: "100%",
  alignItems: "flex-start",
});

export const CollapsibleContent = styled("div")({
  padding: "1rem",
  backgroundColor: colors.WHITE,
  border: `1px solid ${colors.GREY_6}`,
  borderTop: "none",
  borderRadius: "0 0 8px 8px",
});

export const NoSlotsText = styled("p")({
  fontSize: "1rem",
  color: colors.GREY_5,
  textAlign: "center",
  margin: "1rem 0",
});

export const IconWrapper = styled("span")({
  marginRight: "0.5rem", // Adjust to 'marginLeft' if necessary for RTL
  display: "flex",
  alignItems: "center",
});
