export const STORAGE_KEYS = {
  BusinessId: "businessId",
  SelectedService: "selectedService",
  CurrentStep: "currentStep",
  MaxStepReached: "maxStepReached",
  IsRedirecting: "isRedirecting",
  CustomerName: "customerName",
  CustomerPhone: "customerPhone",
} as const;

export type StorageKey = (typeof STORAGE_KEYS)[keyof typeof STORAGE_KEYS];
