import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { ImageWrapper, BusinessName, OwnerName } from "./styles";
import KLButton from "../../components/KLButton";
import { fetchBusiness } from "../../store/duckers/customers/thunk";
import {
  Container,
  InfoText,
  Subtitle,
  TextIconContainer,
  Title,
} from "../styles";
import KLPleaseWait from "../../components/KLPleaseWait";
import useLocalStorage from "../../hooks/useLocalStorage";
import { STORAGE_KEYS } from "../../constants/localStorage";
import { useStep } from "../../context/StepContext";
import { categoryConfigMap } from "../../constants/customers/categoryConfigMap";
import colors from "../../constants/colors";
import { EventAvailable } from "@mui/icons-material";
import useBusinessImage from "../../hooks/useBusinessImage";

const CustomerMainScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { resetSteps } = useStep();
  const { businessId: urlBusinessId } = useParams<{ businessId: string }>();

  const { business, fetchingBusiness, error } = useSelector(
    (state: RootState) => state.customers,
  );

  const { storedValue: storedBusinessId, setValue: setBusinessIdStorage } =
    useLocalStorage<string | null>(STORAGE_KEYS.BusinessId, null);

  const [businessId, setBusinessId] = useState<string | null>(
    urlBusinessId || storedBusinessId,
  );

  useEffect(() => {
    if (urlBusinessId) {
      setBusinessIdStorage(urlBusinessId);
      setBusinessId(urlBusinessId);
    } else if (storedBusinessId) {
      setBusinessId(storedBusinessId);
    }
  }, [urlBusinessId, storedBusinessId, setBusinessIdStorage]);

  useEffect(() => {
    resetSteps();
  }, [resetSteps]);

  const handleBookNow = () => {
    if (businessId) {
      navigate(`/${businessId}/customer-info`);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (!businessId) return;
    dispatch(fetchBusiness(businessId));
  }, [dispatch, businessId]);

  const categoryConfig = business?.category
    ? categoryConfigMap[business.category as keyof typeof categoryConfigMap]
    : undefined;

  const term = categoryConfig?.term || "תור";

  const imageSrc = useBusinessImage({ businessId, categoryConfig });

  if (!businessId) {
    return <Navigate to="/not-authorized" replace />;
  }

  return (
    <Container>
      {fetchingBusiness ? (
        <KLPleaseWait text="בודקים עם מי יש לנו עסק" />
      ) : error ? (
        <>
          <Title>אופס!</Title>
          <InfoText>זה קצת עסק ביש. אנא נסו שוב.</InfoText>
        </>
      ) : business ? (
        <>
          {imageSrc && (
            <ImageWrapper>
              <img src={imageSrc} alt={business.category || "default"} />
            </ImageWrapper>
          )}
          <BusinessName>{business.businessName}</BusinessName>
          <OwnerName>{business.ownerName}</OwnerName>
          <TextIconContainer>
            <EventAvailable
              fontSize="medium"
              style={{ color: colors.GREY_7 }}
            />
            <Subtitle>קבעו {term} באישור מיידי!</Subtitle>
          </TextIconContainer>
          <InfoText>
            עשו זאת בקלות - בחרו את סוג השירות שאתם רוצים, ואת היום והשעה
            שמתאימים לכם, וזהו!
          </InfoText>
          <KLButton title={"בואו נתחיל"} onClick={handleBookNow} />
        </>
      ) : null}
    </Container>
  );
};

export default CustomerMainScreen;
